.fourteen{
    background-color: #F7B587;
    /* width: 92%; */
    /* height: 440px; */
    /* margin: 70px 50px; */
    display: flex;
    flex-direction: row;
    /* width: 1440px; */
height: 674px;
flex-shrink: 0;
}
.contact5{
    color: #80441D;
/* text-align: center; */
font-size: 23px;
font-family: DM Serif Display;
font-style: normal;
font-weight: 300;
line-height: 102.5%;
}
.contact4{
    position: relative;
    top:12%;
    left: 10%
}
#contact9{
    background-color: #F7B587;
    /* color: #F7B587; */
    width:300px;
    border: 1px solid #F7B587;
}
#fifteen_01:hover{
    transform: translateX(0.3rem) translateY(-1rem);
    /* box-shadow: rgba(0, 0, 0, 0.35) 10px 5px 15px; */
    transition: 0.3s ease-in-out;
    cursor: pointer;

}
.fifteen:hover{
    transform: translateX(0.3rem) translateY(-1rem);
    transition: 0.3s ease-in-out;
    cursor: pointer;

}
.fifteen{
    display: flex;
width: 393px;
flex-direction: column;
flex-shrink: 0;
    color: #80441D;
text-align: center;
font-size: 59px;
font-family: DM Serif Display;
font-style: normal;
font-weight: 400;
line-height: 132.5%;
margin-top: 100px;
margin-left: -50px;
transition: 0.3s ease-in-out;

}
.fifteen:contains("FREE"){
    color:white;
}
.fifteen:first-child{
    color: white;
}
.sixteen{
    color: white;
    padding: none;
    margin: none;
}

@media screen and (min-width: 280px) and (max-width: 980px) {
    .fourteen{
        background-color: #F7B587;
        /* width: 92%; */
        /* height: 440px; */
        /* margin: 70px 50px; */
        display: flex;
        flex-direction: column;
        /* width: 1440px; */
    height: 1594px;
    flex-shrink: 0;
    }
    hr{
        width:90%;
        position: relative;
        left:-10%;
    }
    .contact4{
        position: relative;
        top:2%;
        left: 10%;
        margin:3%;
    }
    #contact9{
        background-color: #F7B587;
        /* color: #F7B587; */
        width:300px;
        border: 1px solid #F7B587;
    }
    .fifteen{
        display: flex;
    width: 393px;
    flex-direction: column;
    flex-shrink: 0;
        color: #80441D;
    text-align: center;
    font-size: 35px;
    font-family: DM Serif Display;
    font-style: normal;
    font-weight: 400;
    line-height: 132.5%;
    margin-top: 0px;
    margin-left: 0px;
    }
    #fifteen_1{
        /* width: 50%; */
        /* height: 10; */
    }
    
}