.seven{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.eight{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 25px;
}
.nine{
    height: 239px;
    width: 85%;
    background-color: #CCBAAD;
    padding-bottom: 50px;
    margin-top: 100px;
    transition: 0.3s ease-in-out;

}
#nine{
    position:relative;
    right: -600px;
    bottom: 325px;
}
#nine_1{
    position:relative;
    right: -450px;
    bottom: 305px;
}
#ten{
    color: white;
    font-size: 38px;
    font-family: DM Serif display;
    letter-spacing: 1.26px;
    /* line-height: normal; */
    font-style: normal;
    font-weight: 400;
    /* text-align: center; */
    position:relative;
    left:10%;
}
#seven{
    color: #563822;
    font-size: 38px;
    font-family: DM Serif display;
    letter-spacing: 1.26px;
    /* line-height: normal; */
    font-style: normal;
    font-weight: 400;
    position:relative;
    left:10%;
    bottom: 8%;
}
#nine:hover{
    transform: translateX(0.3rem) translateY(-1rem);
    transition: 0.3s ease-in-out;

}
#eight{
    color: white;
    font-size: 20px;
    font-family: DM Serif display;
    letter-spacing: 1.26px;
    /* line-height: normal; */
    font-style: normal;
    font-weight: 400;
    position:relative;
    left:10%;
    bottom:15%;
}
.text1{
    color: #202020;
    font-size: 48px;
    font-family: DM Serif display;
    letter-spacing: 1.26px;
    line-height: normal;
    font-style: normal;
    font-weight: 400;
}
.text2{
    padding: 0px 170px;
    color: #7D7D7D;
}
.block1{
    width: 193px;
    height:193px;
    flex-shrink: 0;
    background-color: #F3F3F3;
    margin: 20px;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;

}
.block1:hover{
    transform: translateX(0.3rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 10px 5px 15px;
        background: #7DB59C;
        /* transition: 0.3s ease-in-out; */
        cursor: pointer;

        

}
#block_2{
    background: #7DB59C;
}
.svg1{
    display: flex;
    position:relative;
    top:30px;
    left:55px;
}
.svg1:hover{
    /* color:white; */
    /* background-color: white; */
    /* fill: wheat; */
}
.innertext{
    color: #7D7D7D;
text-align: center;
font-size: 20px;
font-family: DM Serif Display;
font-style: normal;
font-weight: 400;
line-height: 132.5%;
position:relative;
top:35px;
left:55px;
}


@media screen and (min-width: 280px) and (max-width: 980px) {
    .text1{
        color: #202020;
        font-size: 30px;
        font-family: DM Serif display;
        letter-spacing: 1.26px;
        line-height: normal;
        font-style: normal;
        font-weight: 400;
        padding: 0px 17px;

    }
    .text2{
        padding: 0px 17px;
        color: #7D7D7D;
    }
    
    .eight{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
    }
    .block1{
        width: 20%;
        height:93px;
        flex-shrink: 0;
        background-color: #F3F3F3;
        margin: 10px;
        justify-content: center;
        align-items: center;
    }
    .svg1{
        width: 53px;
        height: 53px;
        display: flex;
        position:relative;
        top:0px;
        left:0px;
    }
    .innertext{
        color: #7D7D7D;
    text-align: center;
    font-size: 15px;
    font-family: DM Serif Display;
    font-style: normal;
    font-weight: 400;
    line-height: 132.5%;
    position:relative;
    top:0px;
    left:0px;
    }
    .nine{
        height: 239px;
        width: 100%;
        background-color: #CCBAAD;
        padding-bottom: 50px;
        margin-top: 100px;
    }
    #nine{
        width: 60%;
        position:relative;
        right: -40%;
        bottom: 175%;
    }
    #nine_1{
        position:relative;
        /* right: 0px; */
        left:30%;
        bottom: 135px;
        height: 90px;
    }
    #ten{
        color: white;
        font-size: 90%;
        font-family: DM Serif display;
        letter-spacing: 1.26px;
        /* line-height: normal; */
        font-style: normal;
        font-weight: 400;
        /* text-align: center; */
        position:relative;
        left:0%;
    }
    #seven{
        color: #563822;
        font-size: 120%;
        font-family: DM Serif display;
        letter-spacing: 1.26px;
        /* line-height: normal; */
        font-style: normal;
        font-weight: 400;
        position:relative;
        left:0%;
        bottom: 8%;
    }
    #eight{
        color: white;
        font-size: 70%;
        font-family: DM Serif display;
        letter-spacing: 1.26px;
        /* line-height: normal; */
        font-style: normal;
        font-weight: 400;
        position:relative;
        left:0%;
        bottom:15%;
    }
    .nine{
        height: 90px;
        /* width: 85%; */
        background-color: #CCBAAD;
        padding-bottom: 30px;
        margin-top: 100px;
    }
}