.thirteen{
    background-color: #93B990;
    width:85%;
    height: 499px;
    flex-shrink: 0;
    margin-top: 70px;
    margin-bottom: 70px;
    margin-left: 7.5%;
    margin-right:7.5%;
    display: flex;
    flex-direction: row;
}
.pic1{
    width:51px;
    position:relative;
left:-15%;
/* bottom:-20%; */
}
.pic1:hover{
    cursor: pointer;
}
.pic3:hover{
    cursor: pointer;
}
.pic2{
    width: 769px;
height: 248px;
flex-shrink: 0;
position:relative;
left:-5%;
bottom:-20%;
transition: 0.3s ease-in-out;

}
.pic2:hover{
    transform: translateX(0.4rem) translateY(-1rem);
        /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
        transition: 0.3s ease-in-out;
        cursor: pointer;

}
#seven_4{
    color:white;
font-size: 39px;
font-family: DM Serif Display;
font-style: normal;
font-weight: 400;
line-height: 132.5%;
position:relative;
left:42%;
bottom:5%;
}
#seven_3{
    color: #305444;
font-size: 59px;
font-family: DM Serif Display;
font-style: normal;
font-weight: 400;
line-height: 132.5%;
}
.pic3{
    width:51px;
    position: relative;
    /* bottom:-10%; */
    /* right:10%; */
}
#carrosel1:hover{
    cursor: pointer;
}
#carrosel1{
    display:flex;
    flex-direction:row;
    gap:10px;
    position: relative;
    bottom:-90%;
    left:-50%;
    
    /* z-index:3; */

  }
  #carrosel-items1{
    width:17px;
    border-radius:20px;
    height:8px;
    background-color:#202F1F;
  }

@media screen and (min-width: 280px) and (max-width: 980px) {
    .thirteen{
        background-color: #93B990;
        width: 100%;
        height: 329px;
        flex-shrink: 0;
        margin: 40px 0px;
        display: flex;
        flex-direction: row;
    }
    #seven_3{
        color: #305444;
    font-size: 39px;
    font-family: DM Serif Display;
    font-style: normal;
    font-weight: 400;
    line-height: 132.5%;
    }
    #seven_4{
        color:white;
    font-size: 34px;
    font-family: DM Serif Display;
    font-style: normal;
    font-weight: 400;
    line-height: 132.5%;
    display: flex;
    flex-direction: row;
    position:relative;
    left:25%;
    bottom:5%;
    }
    .pic1{
        width:31px;
        position:relative;
    left:-38%;
    /* bottom:-20%; */
    }
    .pic2{
        width: 80%;
    height: 208px;
    flex-shrink: 0;
    position:relative;
    left:-40%;
    bottom:-20%;
    }
    .pic3{
        width:31px;
        position: relative;
        /* bottom:-10%; */
        left:-45%;
    }
    #carrosel1{
        display:flex;
        flex-direction:row;
        gap:5px;
        position: relative;
        bottom:-90%;
        left:-95%;
        
        /* z-index:3; */
    
      }
      #carrosel-items1{
        width:10px;
        border-radius:10px;
        height:8px;
        background-color:#202F1F;
      }
    
}